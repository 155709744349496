import Axios from "axios";
import store from "@/store";

const baseURL =
    process.env.VUE_APP_API_ENV === "DEV"
        ? process.env.VUE_APP_API_DEV_URL
        : process.env.VUE_APP_API_ENV === "STG"
            ? process.env.VUE_APP_API_STG_URL
            : process.env.VUE_APP_API_PROD_URL;

const authRequest = (token, baseURL) => {
  return {
    baseURL,
    headers: {
      Authorization: token,
    },
  };
};

const createAxiosInstance = (token) => {
  const customization = authRequest(token, baseURL);
  const instance = Axios.create(customization);

  instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          store.commit("user/token", null);
          store.commit("user/user", null);
        }
        return Promise.reject(error);
      }
  );

  return instance;
};

export default {
  axios(_, __, ___, rootGetters) {
    const token = rootGetters["user/token"];
    return createAxiosInstance(token);
  },
};
