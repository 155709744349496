<template>
  <base-card class="guard-profile">
    <header class="header">
      <h2 class="header_name">
        {{ securityGuard?.security_guard_name?.toUpperCase() }}
      </h2>
      <user-photo
        show-profile-icon
        :photo="
          securityGuard?.security_guard_picture ??
            require('@/assets/svg/profileimg.svg')
        "
        is-large
      />
    </header>
    <div v-if="isAdmin">
      <div class="center-data-profile">
        <p><span>Email: </span>{{ securityGuard?.security_guard_username }}</p>
        <p>
          <span>Identificación: </span
          >{{ securityGuard?.security_guard_document_id }}
        </p>
      </div>
    </div>
    <div v-if="!isAdmin">
      <div class="center-data-profile">
        <p><span>Email: </span>{{ securityGuard?.security_guard_username }}</p>
        <p>
          <span>Identificación: </span
          >{{ securityGuard?.security_guard_document_id }}
        </p>
        <p   v-if="securityGuard?.security_guard_security_company?.length  > 0">
          <span>Compañia de seguridad: </span
          >{{ securityGuard?.security_guard_security_company }}
        </p>
      </div>
    </div>

    <footer class="footer">
      <base-button
        is-out-line
        :is-orange="false"
        @click="logout"
        class="footer__link"
        txt="Cerrar sesión"
      />
      <base-button
        @click="$emit('on-change-password')"
        class="footer__link"
        txt="Cambiar contraseña"
      />
    </footer>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/card/BaseCard.vue";
import UserPhoto from "@/components/UserPhoto.vue";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { BaseButton, BaseCard, UserPhoto },
  props: {
    onPressed: {
      type: Function
    }
  },

  emits: ["on-change-password"],
  computed: {
    ...mapGetters({
      isAuth: "user/isAuth",
      isAdmin: "user/isAdmin"
    }),
    securityGuard() {
      return this.$store.state.user?.user;
    },

  },
  methods: {
    logout() {
      this.$store.commit("user/token", null);
      localStorage.clear();
      this.$router.go(0);
    }
  }
};
</script>

<style scoped>
* {
  white-space: nowrap;
}

.guard-profile {
  width: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.header {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_name {
  font-size: 24px;
  font-weight: 600;
  margin: 1rem 0;
  color: #606060;
}
.center-data-profile{
   text-align: center;
}
p {
  font-weight: 400;
  color: #606060;
}

span {
  font-weight: 600;
  color: #606060;
}

.footer {
  display: grid;
  justify-content: center;
  margin: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

@media (max-width: 600px) {
  .footer {
    grid-template-columns: 1fr;
  }
}
</style>
