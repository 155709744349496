<template>
  <router-view />
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("user", ["token"]),
  },
  watch: {
    // Observa cambios en el token
    token(newToken) {
      if (!newToken) {
        this.$router.push({ name: "Authentication" });
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;0,800;1,400&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

* {
  box-sizing: border-box;
}
</style>
